import React from "react"

import { Button, Container, Text, Div, Icon, Image } from "atomize"

import navigation from "../../images/tour.svg"

// import navigation from "../../images/landing-screen-2.svg"

class HeroSection extends React.Component {
  render() {
    return (
      <>
        <Div tag="section" p={{ t: { xs: "6rem", md: "10rem" } }}>
          <Container d="flex" flexDir="column" align="center">
            <Text
              tag="h1"
              textWeight="500"
              textAlign="center"
              textSize={{ xs: "display1", sm: "display2", md: "display3" }}
              fontFamily="secondary"
              m={{ b: "1rem" }}
            >
              Dein Corona Navi
            </Text>
            <Text
              tag="h2"
              textWeight="400"
              maxW="36rem"
              textSize="subheader"
              textAlign="center"
              fontFamily="secondary"
              textColor="medium"
              m={{ b: "2.5rem" }}
            >
              Deine zentrale Anlaufstelle für alle Themen rund um Corona.
            </Text>
            <Div
              d="flex"
              w="100%"
              justify="center"
              flexDir={{ xs: "column", sm: "row" }}
            >
              <Button
                rounded="circle"
                h="3rem"
                w={{ xs: "100%", sm: "10rem" }}
                bg="success700"
                hoverBg="succes600"
                // rounded="lg"
                shadow="1"
                hoverShadow="4"
                justify="space-between"
                p={{ x: "1.5rem" }}
                suffix={<Icon name="Message" size="20px" color="white" />}
                m={{ b: { xs: "1rem", sm: "0" } }}
                onClick={() => {
                  if (this.props.openChat) {
                    this.props.openChat(true)
                    typeof window !== "undefined" &&
                      window.plausible &&
                      window.plausible("Open Chat")
                  }
                }}
              >
                Navi starten
              </Button>
            </Div>
            {/* <Div
              d="flex"
              justify="center"
              p={{ b: "6rem", t: { xs: "1.5rem", md: "3rem" } }}
              border={{ b: "1px solid" }}
              borderColor="gray300"
            >
              <Div
                minW={{ xs: "100%", md: "44rem", lg: "59rem" }}
                d="flex"
                align="center"
                flexDir="column"
                h={{ xs: "auto", md: "auto", lg: "auto" }} // h={{ xs: "auto", md: "21rem", lg: "20rem" }}
                pos="relative"
              >
                <Image src={navigation} maxW={{ xs: "30rem", md: "30rem" }} />
              </Div>
            </Div> */}
          </Container>
        </Div>
        <Div
          tag="section"
          w="100vw"
          p={{ t: { xs: "0.5rem", md: "3rem" } }}
          overflow="hidden"
        >
          <Container>
            <Div
              d="flex"
              justify="center"
              p={{ b: "6rem" }}
              border={{ b: "1px solid" }}
              borderColor="gray300"
            >
              <Div
                minW={{ xs: "100%", md: "44rem", lg: "59rem" }}
                d="flex"
                align="center"
                flexDir="column"
                h={{ xs: "auto", md: "auto", lg: "auto" }} // h={{ xs: "auto", md: "21rem", lg: "20rem" }}
                pos="relative"
              >
                <Image src={navigation} maxW={{ xs: "auto", md: "45rem" }} />
              </Div>
            </Div>
          </Container>
        </Div>
      </>
    )
  }
}

export default HeroSection
