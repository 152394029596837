import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroSection from "../components/Landing/HeroSection"
import Loadable from "@loadable/component"
import "../css/global.css"
const Chat = Loadable(() =>
  import(/* webpackPrefetch: true */ "../components/Chat/Chat")
)
const NichtVerfuegbar = Loadable(() =>
  import(/* webpackPrefetch: true */ "../components/Chat/NichtVerfuegbar")
)
const GDPR = Loadable(() =>
  import(/* webpackPrefetch: true */ "../components/Chat/GDPR")
)
const Startups = Loadable(() =>
  import(/* webpackPrefetch: true */ "../components/startups")
)
const About = Loadable(() =>
  import(/* webpackPrefetch: true */ "../components/Landing/about")
)
const SupportedStates = Loadable(() =>
  import(/* webpackPrefetch: true */ "../components/Landing/supportedStates")
)

function IndexPage() {
  const [chatOpen, setChatOpen] = useState(false)
  const [load, setLoad] = useState(false) // defines when the chat starts to connect with the bot
  const [accepted, setAccepted] = useState(false)
  const isSSR = typeof window === "undefined"

  return (
    <Layout>
      <SEO
        title="Dein Corona Navi"
        description="Deine zentrale Anlaufstelle für alle Themen rund um Corona."
      />
      <HeroSection openChat={open => setChatOpen(open)} />
      <GDPR
        open={chatOpen && !accepted}
        onClose={open => setChatOpen(open)}
        onEnter={() => setLoad(true)}
        setAccepted={() => setAccepted(true)}
      />
      {!isSSR && load && (
        // <Chat open={chatOpen && accepted} onClose={open => setChatOpen(open)} />
        <NichtVerfuegbar open={chatOpen && accepted} onClose={open => setChatOpen(open)} />
      )}
      <About />
      <SupportedStates />
      <Startups />
    </Layout>
  )
}

export default IndexPage
